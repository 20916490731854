<template>
    <div class="image-manage-box">
        <div class="image-list-box">
            <image-preview v-for="(item,index) in imageList" :key="index" :url="item" class="item" @onRemove="deletePhoto(item)" :width="previewWidth" :height="previewHeight"></image-preview>
            <image-upload v-if="imageList.length < limit" @onSuccess="uploadImageHandle">
                <div slot="button" class="item upload-btn el-icon-plus" :style="{'width':previewWidth,'height':previewHeight}"></div>
            </image-upload>
        </div>
        <small class="color-info">请选择上传png、jpg格式图片</small>
    </div>
</template>
<script>
import ImagePreview from '@/components/ImagePreview';
import ImageUpload from '@/components/ImageUpload';
export default {
    name: "ImageManage",
    props: {
        imageList: {
            type: Array,
            default: () => []
        },
        limit: {
            type: Number,
            default: 99999999
        },
        previewWidth:{
            type: String,
            default: '160px'
        },
        previewHeight:{
            type: String,
            default: '160px'
        }
    },
    components:{
        ImagePreview,ImageUpload
    },
    data(){
        return{

        }
    },
    methods:{
        //删除图片
        deletePhoto(item){
            const _imageList = this.imageList.filter(v => v !== item);
            this.$emit("update:imageList",_imageList);
        },
        //上传图片
        uploadImageHandle(img){
            this.imageList.push(img);
            this.$emit('onUploadSuccess', img);
        },
    }
}
</script>

<style lang="scss" scoped>
.image-manage-box{
    .image-list-box{
        display: flex;
        flex-wrap: wrap;
        .item{
            margin: 10px 10px 0 0;
        }
        .upload-btn{
            width: 160px;
            height: 160px;
            border:1px #eee dashed;
            display: flex;
            align-content: center;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-size: 40px;
            color: #eee;
        }
    }
}
</style>