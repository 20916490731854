<template>
    <div>
        <div class="image-upload-bar">
            <el-upload
                ref="imageUpload"
                :disabled="disabled || uploadLoading"
                action
                :accept="accept"
                :http-request="uploadFileHandle"
                :show-file-list="false"
                :before-upload="beforeUploadHandle"
            >
                <slot name="button"><el-button>选择图片</el-button></slot>
            </el-upload>
        </div>
        <el-dialog
            title="裁剪图片"
            :visible.sync="dialogVisible"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            width="600px"
            append-to-body
        >
            <div class="cropper-content" :style="{height: copperOptions.cropHeight+'px'}">
                <vue-cropper
                    ref="cropper"
                    :img="copperOptions.img"
                    :fixed="true"
                    :auto-crop="true"
                    output-type="png"
                    :fixed-number="copperOptions.fixedNumber"
                    :fixedBox="true"
                    :auto-crop-width="copperOptions.cropWidth"
                    :auto-crop-height="copperOptions.cropHeight"
                    :enlarge="copperOptions.enlarge"
                ></vue-cropper>
            </div>
            <template v-if="progress && progress < 100 && uploadLoading">
                <el-progress :percentage="progress"></el-progress>
            </template>
            <div slot="footer" class="dialog-footer clearfix">
                <div class="tool fl">
                    <el-button icon="el-icon-zoom-in" @click="changeScale(1)"></el-button>
                    <el-button icon="el-icon-zoom-out" @click="changeScale(-1)"></el-button>
                    <el-button icon="el-icon-refresh-left" @click="rotateLeft"></el-button>
                    <el-button icon="el-icon-refresh-right" @click="rotateRight"></el-button>
                </div>
                <div class="btn fr">
                    <el-button @click="uploadFileAction(imgBase64)" :loading="uploadLoading">原图上传</el-button>
                    <el-button type="primary" @click="finish" :loading="uploadLoading">裁剪上传</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {VueCropper}  from 'vue-cropper'
import {uploadImgByBase64} from '@/assets/js/commonApi.js';
const FIXEDNUMBER = {
    '1:1': [400, 400],
    '4:3': [600, 400]
}
export default {
    name: "ImageUpload",
    props: {
        accept:{
            type: String,
            default: 'image/png,image/jpeg,image/gif',
        },
        fixedNumber: {
            type: String,
            default: '1:1'
        },
        size: {
            type: Number,
            default: 15
        },
        disabled: {
            type: Boolean,
            default: false
        },
        minWidth: {
            type: Number,
            default: 0
        },
        minHeight: {
            type: Number,
            default: 0
        }
    },
    components: { VueCropper },
    data() {
        return {
            uploadLoading: false,
            formatVerified: false,
            bytesVerified: false,
            progress: null,
            dialogVisible: false,
            copperOptions:{
                img:'',
                fixedNumber:[1, 1],
                cropWidth: 600,
                cropHeight: 600,
                enlarge:''
            },
            imgBase64: null,
            sourceWidth: 0,
            sourceHeight: 0,
        };
    },
    watch: {
        fixedNumber: {
            immediate: true,
            handler() {
                const current = FIXEDNUMBER[this.fixedNumber];
                if(!current) return this.$message.error('裁切图片初始数值设置失败');
                this.copperOptions.fixedNumber = current;
                this.copperOptions.cropWidth = current[0];
                this.copperOptions.cropHeight = current[1];
            }
        },
    },
    methods: {
        resetRatio () {
            const {cropWidth, cropHeight} = this.copperOptions;
            const {sourceWidth, sourceHeight} = this;
            let enlarge = 0;
            if( cropWidth > cropHeight ){
                enlarge = sourceWidth > cropWidth ? sourceWidth/cropWidth : 1
            }else{
                enlarge = sourceHeight > cropHeight ?  sourceHeight/cropHeight : 1
            }
            this.copperOptions.enlarge = enlarge;
        },
        //上传进度
        setProgress(ev){
            this.progress = Math.floor((ev.loaded / ev.total) * 50);
            if(Math.floor((ev.loaded / ev.total)) == 1){
                let t = setInterval(() => {
                    if(this.progress < 99 && this.uploadLoading) {
                        this.progress += 1;
                    }else{
                        clearInterval(t);
                    }
                },1000)
            }
        },
        /**
         * 获取图片宽高
         * @param {*} file 图片流
         * @return {Promise}
         */
        imageWH(file) {
            return new Promise(resolve => {
                const reader = new FileReader();
                reader.onload = e => {
                    var data = e.target.result;
                    var image = new Image();
                    image.onload = () => {
                        resolve({w: image.width, h: image.height, src: data});
                    };
                    image.src = data;
                };
                reader.readAsDataURL(file);
            });
        },
        //上传验证
        async beforeUploadHandle(file){
            let isArea = true;
            //格式检查
            let _accept = this.accept.split(',') || [];
            _accept.map( (item,index) => {
                _accept[index] = item.trim();
            })
            this.formatVerified = _accept.find( v => v == file.type) ? true : false;
            if (!this.formatVerified) {
                this.$message.error("文件格式错误,请重新上传");
            }

            //大小检查
            this.bytesVerified = file.size / 1024 / 1024 <= this.size ? true : false;
            if (!this.bytesVerified) {
                this.$message.error(`文件不能大于${this.size}M,请重新上传`);
            }

            // 判断尺寸大小
            const image = await this.imageWH(file);
            this.sourceWidth = image.w;
            this.sourceHeight = image.h;
            if(this.minWidth && this.minHeight) {
                if (image.w < this.minWidth || image.h < this.minHeight) {
                    this.$message.error(`请上传不小于${this.minWidth}*${this.minHeight}像素的图片`);
                    isArea = false;
                }
            }
            if(isArea) {
                this.resetRatio();
                this.copperOptions.img = image.src;
                this.dialogVisible = true;
            }
            
            return this.formatVerified && this.bytesVerified && isArea
        },
        //自动上传事件
        uploadFileHandle(req){
            const self = this;
            const {file} = req;

            let reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onloadend = function() {
                // self.uploadFileAction(reader.result);
                self.imgBase64 = reader.result;
            }
            
        },
        //图片上传
        async uploadFileAction(req){
            this.uploadLoading = true;
            this.progress = 0;

            let res;
            const formData = new FormData();

            formData.append("file", req);

            res = await uploadImgByBase64(formData,ev => {
                this.setProgress(ev);
            }).catch(() => {
                this.$message.error( '上传失败');
            }).finally(() => {
                this.progress = 99;
                setTimeout( () => {this.uploadLoading = false;},800);
                this.dialogVisible = false;
            });

            const {state,result,msg} = res.data;
            if( state && state == 1 && result){
                this.$emit('onSuccess',result.urlfull);
            }else{
                this.$message.error( msg || '上传失败');
            }
        },
        //完成裁剪
        finish(){
            this.$refs.cropper.getCropData( async data => {
                await this.uploadFileAction(data);
            });
        },
        //裁剪图片放大、缩小
        changeScale(num){
            num = num || 1 
            this.$refs.cropper.changeScale(num)
        },
        //裁剪图片左旋转
        rotateLeft(){
            this.$refs.cropper.rotateLeft();
        },
        //裁剪图片右旋转
        rotateRight(){
            this.$refs.cropper.rotateRight();
        },
    },
};
</script>

<style lang="scss" scoped>

</style>