<template>
    <div class="image-preview-wrap" :style="{width:width}">
        <div class="image-preview-box" :style="{height:height}">
            <el-image 
                :src="url" 
                fit="contain" 
            >
                <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                </div>
            </el-image>
            <div v-if="url" class="tool">
                <slot name="tool">
                    <div>
                        <span class="tool-btn img-preview el-icon-zoom-in" @click="preview"></span>
                        <el-button class="tool-btn" icon="el-icon-delete" type="text" @click="remove"></el-button>
                    </div>
                </slot>
            </div>
        </div>

        <el-dialog :visible.sync="previewVisible" title="高清预览" center append-to-body>
            <div class="preview-box">
                <img :src="url">
            </div>
        </el-dialog>
    </div>               
</template>

<script>
export default {
    props:{
        url: {
            type: String,
            default: () => "",
        },
        width: {
            type: String,
            default: '160px'
        },
        height: {
            type: String,
            default: '160px'
        }
    },
    data(){
        return{
            previewVisible: false
        }
    },
    methods:{
        //预览
        preview(){
            this.previewVisible = true
        },
        //清除
        remove(){
            this.$emit(
                "update:url",""
            );
            this.$emit("onRemove");
        },
    }
}
</script>
<style lang="scss" scoped>
.image-preview-wrap{
    // width: 160px;
    .image-preview-box{
        height: 100%;
        /* margin-right: 15px; */
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        background: #f7f7f7;
        // border-radius: 8px;
        position: relative;
        overflow: hidden;
        .el-image{
            width: 100%;
            height: 100%;
        }
        .tool{
            position: absolute;
            background: rgba(0,0,0,0.5);
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            display: none;
            align-items: center;
            justify-content: center;
            *{
                color: #ffffff;
                font-size: 18px;
                font-weight: 700;
                margin: 0 5px;
                cursor: pointer;
            }
        }
        &:hover{
            .tool{
                display: flex;
            }
        }
    }
    .image-upload-btn{
        width: 100%;
    }
}

.preview-box{
    text-align: center;
    img{
        max-width: 100%;
    }
}
</style>