import request from '@/assets/js/request';

/**
 * @description 上传图片
 *  @param {*} formData
 */

export function uploadImg(formData, onUploadProgress){
    return request({
        formData:false,
        url: '/api/ht/common/uploadimg',
        method: 'post',
        data: formData,
        onUploadProgress: onUploadProgress || function() {}
    })
}

/**
 * @description 图片上传（base64）
 * @param {*} formData
 */
export function uploadImgByBase64(formData, onUploadProgress) {
    return request({
        formData:false,
        url: '/api/ht/common/uploadimg64',
        method: 'post',
        // timeout: 60 * 60 * 1000,
        data: formData,
        onUploadProgress: onUploadProgress || function() {}
    });
}

/**
 * @description 上传视频
 * @param {*} formData
 */
export function uploadVideo(formData, onUploadProgress) {
    return request({
        formData:false,
        url: '/api/ht/common/uploadvideo',
        method: 'post',
        // timeout: 60 * 60 * 1000,
        data: formData,
        onUploadProgress: onUploadProgress || function() {}
    });
}


/**
 * @description 地域信息，省、市、县，树形结构
 */
export function allarea() {
    return request({
        url: '/api/ht/common/allarea',
        method: 'post',
    });
}

